// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results_list_answers__4NOU2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.results_list_answer__ax83b {
  height: 66px;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.results_list_answerIcon__OAIyS {
  position: absolute;
  right: 13px;
  top: 23px;
  color: white;
}

.results_list_answerPosition__xmU9Q {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.64px;
  letter-spacing: -0.0143em;
  text-align: center;
  width: 60px;
}

.results_list_answerText__8zaa9 {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  flex: 1 1;
  max-height: 100%;
  text-overflow: ellipsis;
  padding-right: 25px;
}

.results_list_answerImage__VnFGl {
  height: 160px;
  width: 60px;
  transform: perspective(40px) rotateX(27deg);
  margin: -10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/components/ResultsList/results.list.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,SAAO;EACP,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,2CAA2C;EAC3C,aAAa;AACf","sourcesContent":[".answers {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  align-items: center;\n}\n\n.answer {\n  height: 66px;\n  background: white;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 10px;\n  overflow: hidden;\n  position: relative;\n}\n\n.answerIcon {\n  position: absolute;\n  right: 13px;\n  top: 23px;\n  color: white;\n}\n\n.answerPosition {\n  color: #000000;\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 28.64px;\n  letter-spacing: -0.0143em;\n  text-align: center;\n  width: 60px;\n}\n\n.answerText {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 16px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #000000;\n  flex: 1;\n  max-height: 100%;\n  text-overflow: ellipsis;\n  padding-right: 25px;\n}\n\n.answerImage {\n  height: 160px;\n  width: 60px;\n  transform: perspective(40px) rotateX(27deg);\n  margin: -10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answers": `results_list_answers__4NOU2`,
	"answer": `results_list_answer__ax83b`,
	"answerIcon": `results_list_answerIcon__OAIyS`,
	"answerPosition": `results_list_answerPosition__xmU9Q`,
	"answerText": `results_list_answerText__8zaa9`,
	"answerImage": `results_list_answerImage__VnFGl`
};
export default ___CSS_LOADER_EXPORT___;
