import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ApiClientProvider } from "./providers/api-client";
import "@fontsource/inter";
import { StylesProvider } from "./providers/styles/styles-provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ApiClientProvider>
      <StylesProvider>
        <App />
      </StylesProvider>
    </ApiClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
