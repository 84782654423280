import styles from "./notFound.module.css";

import { ReactComponent as Logo } from "../../../assets/images/404.svg";
import { useStyles } from "../../../providers/styles/use-styles";

const NotFoundScreen = () => {
  const { getStyles } = useStyles();

  return (
    <div className={styles.wrapper}>
      <h3
        className={styles.title}
        style={getStyles(["title_xl_bold", "txt_label_primary_base"])}
      >
        Что-то пошло не так
      </h3>
      <Logo className={styles.image} />
      <p
        className={styles.description}
        style={getStyles(["txt_label_secondary_base"])}
      >
        К сожалению мы не можем показать вам содержимое данной страницы,
        попробуйте еще раз
      </p>
    </div>
  );
};

export default NotFoundScreen;
