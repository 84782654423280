import React from "react";
import "./Loading.css";
import cupLogo from "../../../../assets/images/cup-silver-gold-300.svg";
import { useStyles } from "../../../../providers/styles/use-styles";

const Loading = () => {
  const { getStyles } = useStyles();

  return (
    <div className="splash-screen">
      <div className="golden-cup">
        <img src={cupLogo} className="logo" alt="Daily cup logo" />
      </div>
    </div>
  );
};

export default Loading;
