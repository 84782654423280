import React, { CSSProperties, useEffect, useState } from "react";
import { StylesContext } from "./styles-context";
import { GameTheme } from "../../app/models/game-theme.entity";

type Props = {
  children: React.ReactNode;
};

export const StylesProvider = (props: Props) => {
  const [theme, setTheme] = useState<GameTheme>();

  useEffect(() => {
    const background = getGradient("gradient_bg_screen_primary_base");

    if (background) {
      document.documentElement.style.setProperty("--bodyColor", background);
    }

    const errorBackground = getColor("bg_toast_primary_error");
    if (errorBackground) {
      document.documentElement.style.setProperty(
        "--toastify-color-error",
        errorBackground
      );
    }

    const errorTextColor = getColor("txt_toast_primary_base");
    if (errorTextColor) {
      document.documentElement.style.setProperty(
        "--toastify-text-color-error",
        errorTextColor
      );
    }
  }, [theme]);

  const getStyles = (tags: string[]): CSSProperties => {
    let result: CSSProperties = {};

    if (!theme) {
      return result;
    }

    tags.forEach((tag) => {
      if (tag.startsWith("txt_")) {
        result["color"] = getColor(tag);
      }

      if (tag.startsWith("bg_")) {
        result["backgroundColor"] = getColor(tag);
      }

      if (tag.startsWith("border_")) {
        result["borderColor"] = getColor(tag);
      }

      if (tag.startsWith("title_")) {
        result = { ...result, ...getFont(tag) };
      }

      if (tag.startsWith("gradient_")) {
        result.background = getGradient(tag);
      }
    });

    return result;
  };

  const getColor = (key: string): string | undefined => {
    return theme?.colors.find((c) => c.key === key)?.value;
  };

  const getFont = (key: string): CSSProperties | undefined => {
    const font = theme?.typography.find((c) => c.key === key)?.value;

    return {
      fontFamily: font?.font_family,
      fontSize: font?.font_size,
      fontStyle: font?.font_style,
      fontWeight: font?.font_weight,
      lineHeight: font?.line_height,
    };
  };

  const getGradient = (key: string): string | undefined => {
    const gradient = theme?.gradients.find((g) => g.key === key)?.value;

    if (!gradient) {
      return undefined;
    }

    const stops = gradient.stops
      .map((s) => `${s.color} ${s.position * 100}%`)
      .join(", ");

    return `${gradient.gradient_type}-gradient(${gradient.rotation}deg, ${stops})`;
  };

  return (
    <StylesContext.Provider
      value={{
        getStyles,
        setTheme,
      }}
    >
      {props.children}
    </StylesContext.Provider>
  );
};

//   "typography": [],
//   "gradients": [
//       {
//           "value": {
//               "gradient_type": "linear",
//               "rotation": 180,
//               "stops": [
//                   {
//                       "color": "#2059ffff",
//                       "position": 0
//                   },
//                   {
//                       "color": "#a258ffff",
//                       "position": 1
//                   }
//               ]
//           },
//           "key": "gradient_bg_screen_primary_base"
//       },
//       {
//           "value": {
//               "gradient_type": "linear",
//               "rotation": 340,
//               "stops": [
//                   {
//                       "color": "#e98b10ff",
//                       "position": 0
//                   },
//                   {
//                       "color": "#ffb932ff",
//                       "position": 1
//                   }
//               ]
//           },
//           "key": "gradient_bg_btn_primary_active"
//       }
//   ]
// }
