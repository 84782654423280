// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash_main__uGu4O {
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.splash_content__ULrls {
  padding: 40px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.splash_wrapper__FyCL5 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.splash_title__urD6I {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.splash_description__f0t2c {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  margin-top: 8px;
}

.splash_logo__3uS7A {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.splash_banner__L4Nv4 {
  width: 100%;
  height: auto;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/components/Splash/splash.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".main {\n  padding: 0px 0px;\n  display: flex;\n  flex-direction: column;\n  height: 100dvh;\n}\n\n.content {\n  padding: 40px 36px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n}\n\n.wrapper {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  gap: 34px;\n}\n\n.title {\n  font-size: 40px;\n  font-weight: 700;\n  line-height: 40px;\n  letter-spacing: 0em;\n  text-align: center;\n  color: white;\n}\n\n.description {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: center;\n  color: white;\n  margin-top: 8px;\n}\n\n.logo {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n.banner {\n  width: 100%;\n  height: auto;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `splash_main__uGu4O`,
	"content": `splash_content__ULrls`,
	"wrapper": `splash_wrapper__FyCL5`,
	"title": `splash_title__urD6I`,
	"description": `splash_description__f0t2c`,
	"logo": `splash_logo__3uS7A`,
	"banner": `splash_banner__L4Nv4`
};
export default ___CSS_LOADER_EXPORT___;
