import React from "react";
import { StylesContext } from "./styles-context";
import { StylesContextData } from "./styles-context-data";

export function useStyles() {
  const ctx = React.useContext<StylesContextData | null>(StylesContext);

  if (!ctx) {
    throw new Error("styles context not provided");
  }

  return ctx;
}
