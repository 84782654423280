import { QuestionResult } from "../../../models/game-result.entity";
import { useStyles } from "../../../../providers/styles/use-styles";
import styles from "./results.list.module.css";
import { ReactComponent as Valid } from "../../../../assets/images/valid.svg";
import { ReactComponent as Invalid } from "../../../../assets/images/invalid.svg";

interface Props {
    items: QuestionResult[];
}

const ResultsList = ({ items }: Props) => {
    const { getStyles } = useStyles();

    return (
        <div className={styles.answers}>
            {items.map((result, position) => (
                <div className={styles.answer}>
                    <span
                        className={styles.answerPosition}
                        style={getStyles(["txt_cell_primary_base"])}
                    >
                        {position + 1}
                    </span>
                    <span
                        className={styles.answerText}
                        style={getStyles(["txt_cell_primary_base"])}
                    >
                        {result.question.text}
                    </span>

                    <div
                        className={styles.answerImage}
                        style={getStyles([
                            result.answer.is_correct
                                ? "bg_icon_primary_success"
                                : "bg_icon_primary_error",
                        ])}
                    />

                    <div
                        className={styles.answerIcon}
                    >
                        {result.answer.is_correct ? <Valid /> : <Invalid />}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ResultsList;