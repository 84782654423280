import React, { useEffect, useMemo, useState } from "react";
import styles from "./results.module.css";
import { GameSessionAdvertisementInfo, QuestionResult } from "../../../models/game-result.entity";
import { useApiClient } from "../../../../providers/api-client";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import { useStyles } from "../../../../providers/styles/use-styles";
import ResultsList from "../ResultsList/ResultsList";
import QuizAdverisement from "../QuizAdvertisement/QuizAdvertisement";
import { ReactComponent as BackButtonImage } from "../../../../assets/images/ic_back_button.svg";

interface Props {
  token: string;
  integrationId: string;
}

interface ContactLink {
  email?: string
}

const Results = ({ token, integrationId }: Props) => {
  const client = useApiClient();
  const { getStyles } = useStyles();

  const [results, setResults] = useState<QuestionResult[]>();
  const [showDetails, setShowDetails] = useState(false);
  const [isContactAvailable, setContactAvailable] = useState(false);
  const [contactLink, setContactLink] = useState<ContactLink>();
  const [advertisement, setAdvertisement] = useState<GameSessionAdvertisementInfo>();
  const [email, setEmail] = useState<string>();
  const [savingEmail, setSavingEmail] = useState(false);

  function setContactEmail(email: string) {
    setEmail(email);
  }

  function submitEmail() {
    if (email) {
      const link: ContactLink = {
        email: email
      }
      setSavingEmail(true);
      client
        .postContactInfo(token, email)
        .then((result) => {
          setContactLink(link);
        })
        .catch((error: any) => {
          console.log(error);

          toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
            position: "top-center",
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        })
        .finally(() => {
          setSavingEmail(false);
        });
    }
  }

  function onBack() {
    setShowDetails(false);
  }

  useEffect(() => {
    client
      .getResults(token, integrationId)
      .then((result) => {
        setResults(result.results);
        const link: ContactLink = {
          email: result.contact?.email
        }
        setContactLink(link);
        setContactAvailable(result.is_contact_needed);
        setAdvertisement(result.advertisement);
      })
      .catch((error: any) => {
        console.log(error);

        toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
          position: "top-center",
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      });
  }, []);

  const correctResults = useMemo(() => {
    return results?.filter((r) => r.answer.is_correct).length ?? 0;
  }, [results]);

  return (
    <div>
      <div className={styles.toolbar}>
        <div className={styles.toolbarIconContainer}>
          {showDetails && (
            <div
              className={styles.backButton}
              style={getStyles(["txt_label_primary_base"])}
              onClick={onBack}
            >
              <BackButtonImage />
            </div>
          )}
        </div>
        <h3
          className={styles.title}
          style={getStyles(["txt_label_primary_base"])}
        >
          Результаты
        </h3>
        <div className={styles.toolbarIconContainer} />
      </div>

      <div className={styles.wrapper}>

        {results ? (
          <div>
            <p
              className={styles.description}
              style={getStyles(["txt_label_secondary_base"])}
            >
              Вы набрали {correctResults} из {results.length}
            </p>
            {showDetails ? (
              <ResultsList items={results} />
            ) : (
              <>
                <ResultsList items={results.slice(0, 3)} />
                <div className={styles.actionWrapper}>
                  <Button title="Посмотреть все ответы" onClick={() => setShowDetails(true)} />
                </div>
              </>
            )}
          </div>
        ) : (
          <img
            className={styles.spinner}
            src="/images/circular-indeterminate-thin.svg"
            width={48}
            height={48}
            alt="загрузка"
          />
        )}

        {isContactAvailable && (
          <div>
            {contactLink?.email ? (
              <div className={styles.inputWrapper}>
                <p
                  className={styles.description}
                  style={getStyles(["txt_label_secondary_base"])}
                >
                  Вы указали email для связи в случае выигрыша
                </p>
                <input
                  className={styles.input}
                  style={getStyles([
                    "txt_chips_primary_base",
                    "border_chips_primary_base",
                  ])}
                  value={contactLink.email}
                  disabled={true}
                />
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                <p
                  className={styles.description}
                  style={getStyles(["txt_label_secondary_base"])}
                >
                  Укажите email, чтобы мы могли связаться с вами в случае выигрыша
                </p>
                <input
                  className={styles.input}
                  style={getStyles([
                    "txt_chips_primary_base",
                    "border_chips_primary_base",
                  ])}
                  type="email"
                  placeholder="Укажите email для связи"
                  disabled={savingEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  maxLength={40}
                />
                <Button title="ОТПРАВИТЬ" isLoading={savingEmail} onClick={submitEmail} />
              </div>
            )}
          </div>
        )}

        <div className={styles.spacer} />

        {advertisement && (
          <QuizAdverisement
            className={styles.advertisementContainer}
            title={advertisement.title}
            description={advertisement.description}
            actionButtonTitle={advertisement.action_button_title}
            url={advertisement.url}
          />
        )}
      </div>

    </div>
  );
};

export default Results;
