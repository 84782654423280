// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz_advertisement_main__UpQAB {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz_advertisement_title__QvGWV {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.quiz_advertisement_description__vhxD1 {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
}

.quiz_advertisement_buttonContainer__tgvQT {
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/components/QuizAdvertisement/quiz.advertisement.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".main {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.title {\n    font-size: 32px;\n    font-weight: 700;\n    line-height: 40px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: white;\n}\n\n.description {\n    color: white;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n    text-align: center;\n    margin-top: 8px;\n}\n\n.buttonContainer {\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `quiz_advertisement_main__UpQAB`,
	"title": `quiz_advertisement_title__QvGWV`,
	"description": `quiz_advertisement_description__vhxD1`,
	"buttonContainer": `quiz_advertisement_buttonContainer__tgvQT`
};
export default ___CSS_LOADER_EXPORT___;
