import styles from "./quiz.advertisement.module.css"
import { useStyles } from "../../../../providers/styles/use-styles";
import Button from "../../../components/Button/Button";

interface Props {
    className?: string,
    title: string,
    description: string,
    actionButtonTitle: string,
    url: string;
}

const QuizAdverisement = ({ className, title, description, actionButtonTitle, url }: Props) => {

    const { getStyles } = useStyles();

    function onClick() {
        window.open(url, "_blank");
    }

    return (
        <div className={className}>
            <div className={styles.main}>
                <h3
                    className={styles.title}
                    style={getStyles(["txt_label_primary_base"])}
                >
                    {title}
                </h3>

                <p
                    className={styles.description}
                    style={getStyles(["txt_label_secondary_base"])}
                >
                    {description}
                </p>

                <div className={styles.buttonContainer}>
                    <Button
                        title={actionButtonTitle}
                        onClick={onClick} />
                </div>
            </div>
        </div>
    )
};

export default QuizAdverisement;