import React, { CSSProperties, FC } from "react";
import styles from "./button.module.css";
import { useStyles } from "../../../providers/styles/use-styles";

interface Props {
  title: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

const Button = ({ title, isDisabled, isLoading, onClick }: Props) => {
  const { getStyles } = useStyles();

  return (
    <button
      className={styles.container}
      onClick={() => !isLoading && onClick()}
      style={getStyles(["gradient_bg_btn_primary_active"])}
    >
      <div
        className={styles.wrapper}
        style={getStyles(["txt_btn_primary_base"])}
      >
        {isLoading && (
          <img
            className={styles.spinner}
            src="/images/circular-indeterminate.svg"
            width={20}
            height={20}
          />
        )}
        {title}
      </div>
    </button>
  );
};

export default Button;
